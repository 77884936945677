import style from './Contact.module.scss';

const Contact = () => {

  const handleSubmit = event => {
    event.preventDefault();
  };

  return (
    <div className="container text-center my-5">
      <h3 className="text-primary mb-4">Contact Us</h3>
      <h6 className="text-lighter-gray fw-normal">Need more information? Get in touch<br/>with us.</h6>
      <div className="row">
        <form
          onSubmit={ handleSubmit }
          className={ `col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 p-5 my-5 ${ style.form }` }>
          <h5 className="text-primary mb-5">Send a Message</h5>
          <div className="row my-4">
            <div className="col-12 col-lg-5">
              <input
                type="text"
                className={ `form-control mb-3 ${ style.input }` }
                placeholder="Name" />
              <input
                type="text"
                className={ `form-control my-3 ${ style.input }` }
                placeholder="Surname" />
              <input
                type="text"
                className={ `form-control mt-3 ${ style.input }` }
                placeholder="Phone" />
            </div>
            <div className="col-12 col-lg-7 mt-3 mt-lg-0">
              <textarea className={ `form-control h-100 ${ style.input }` } placeholder="Message" />
            </div>
          </div>
          <div className="text-lg-end">
            <button
              className={ `btn btn-outline-primary btn-lg fs-16 text-white ${ style.sendButton }` }
              type="submit">
              Send
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
