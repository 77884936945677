import phone1 from '../../../../assets/phone-1.png';
import phone2 from '../../../../assets/phone-2.png';
import qrCube from '../../../../assets/qr-cube.png';
import appStore from '../../../../assets/apple-store.svg';
import appStore_Download from '../../../../assets/download_on_the_app_store.svg';



import style from './About.module.scss';

const About = () => {
  return (
    <div className={ `py-3 position-relative ${ style.wrapper }` }>
      <div className="container text-center">
        <div className="mt-5 mb-4">
          <h1 className="fw-light text-uppercase">Secure</h1>
          <h1>Document Signing</h1>
        </div>
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1 fw-light">
            A user verifiable electronic signature that is unique to each document and each signing.
            No additional certificates / attachments are required. Your smart device confirms your
            identity and our platform confirms your signing. No two signatures are the same.
          </div>
          <div className={ 'mt-5' }>
            {/* <a href="#" className="btn btn-primary btn-lg me-3 fs-14 disabled">Coming Soon</a> */}
            <a className="ms-2" href="https://apps.apple.com/app/apple-store/id1571283461?pt=120459558&ct=web-top&mt=8" rel="noreferrer" target="_blank">
              <img src={ appStore_Download } alt="Apple App Store" className={`img-fluid center-block ${style.imagebutton}`}/>
            </a>
            {/* <a href="#" className="btn btn-outline-primary btn-lg fs-14">Learn More</a> */}
          </div>
        </div>
        {/* THOSE BUTTONS WILL BE ADDED LATER */}
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1 mt-3">
            <img className={ style.phone1 } src={ phone1 } alt="Phone" />
          </div>
        </div>
        <h2 className="fw-normal mb-4">About LockSign</h2>
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1 fw-light">
            Each signature should be unique so we have made it so. By creating a transaction id
            based on your biometric confirmation the signature becomes unique not only to the user
            but also to the document and moment of signing and as such cannot be forged or copied.
            The data is encrypted and stored in the QR code and can be verified by the user or any authorized individual.
            No biometric data is stored on our servers, all is done by your existing smart device.
          </div>
        </div>
        <div className="mt-5">
          <img className={ style.qrCube } src={ qrCube } alt="QR Cube" />
          <img className={ style.phone2 } src={ phone2 } alt="Phone" />
        </div>
      </div>
    </div>
  );
};

export default About;
