const Allsections = () => {
    return (
        <div>

<p>Last updated: September 09, 2019</p>

<p>
  These Terms and Conditions (&quot;Terms&quot;, &quot;Terms and
  Conditions&quot;) govern your relationship with{" "}
  <a href="../f5732e40-1dab-46a5-a223-e9dd569c659a-fcd1bfc6-4344-434c-a3d3-336171dcd6d7/www.locksign.com">
    <span>www.locksign.com</span>
  </a>
  website and LockSign mobile application (the &quot;Service&quot;)
  operated by LockSign LLC (&quot;us&quot;, &quot;we&quot;, or
  &quot;our&quot;).
</p>

<p>
  Please read these Terms and Conditions carefully before using our
  website and LockSign mobile application (the &quot;Service&quot;).
</p>

<p>
  Your access to and use of the Service is conditioned on your
  acceptance of and compliance with these Terms. These Terms apply to
  all visitors, users and others who access or use the Service.
</p>

<p>
  By accessing or using the Service you agree to be bound by these
  Terms. If you disagree with any part of the terms then you may not
  access the Service.
</p>

<h2>Purchases</h2>

<p>
  If you wish to purchase any product or service made available through
  the Service (&quot;Purchase&quot;), you may be asked to supply certain
  information relevant to your Purchase including, without limitation,
  your credit card number, the expiration date of your credit card, your
  billing address, and your shipping information.
</p>

<p>
  You represent and warrant that: (i) you have the legal right to use
  any credit card(s) or other payment method(s) in connection with any
  Purchase; and that (ii) the information you supply to us is true,
  correct and complete.
</p>

<p>
  By submitting such information, you grant us the right to provide the
  information to third parties for purposes of facilitating the
  completion of Purchases.
</p>

<p>
  We reserve the right to refuse or cancel your order at any time for
  certain reasons including but not limited to: product or service
  availability, errors in the description or price of the product or
  service, error in your order or other reasons.
</p>

<p>
  We reserve the right to refuse or cancel your order if fraud or an
  unauthorised or illegal transaction is suspected.
</p>

<h2>Availability, Errors and Inaccuracies</h2>

<p>
  We are constantly updating our offerings of products and services on
  the Service. The products or services available on our Service may be
  mispriced, described inaccurately, or unavailable, and we may
  experience delays in updating information on the Service and in our
  advertising on other web sites.
</p>

<p>
  We cannot and do not guarantee the accuracy or completeness of any
  information, including prices, product images, specifications,
  availability, and services. We reserve the right to change or update
  information and to correct errors, inaccuracies, or omissions at any
  time without prior notice.
</p>

<h2>Content</h2>

<p>
  Our Service allows you to post, link, store, share and otherwise make
  available certain information, text, graphics, videos, or other
  material (&quot;Content&quot;). You are responsible for the Content
  that you post to the Service, including its legality, reliability, and
  appropriateness.
</p>

<p>
  By posting Content to the Service, you grant us the right and license
  to use, modify, perform, display, reproduce, and distribute such
  Content on and through the Service. You retain any and all of your
  rights to any Content you submit, post or display on or through the
  Service and you are responsible for protecting those rights.
</p>

<p>
  You represent and warrant that: (i) the Content is yours (you own it)
  or you have the right to use it and grant us the rights and license as
  provided in these Terms, and (ii) the posting of your Content on or
  through the Service does not violate the privacy rights, publicity
  rights, copyrights, contract rights or any other rights of any person.
</p>

<h2>Accounts</h2>

<p>
  When you create an account with us, you must provide us information
  that is accurate, complete, and current at all times. Failure to do so
  constitutes a breach of the Terms, which may result in immediate
  termination of your account on our Service.
</p>

<p>
  You are responsible for safeguarding the password that you use to
  access the Service and for any activities or actions under your
  password, whether your password is with our Service or a third-party
  service.
</p>

<p>
  You agree not to disclose your password to any third party. You must
  notify us immediately upon becoming aware of any breach of security or
  unauthorized use of your account.
</p>

<p>
  You may not use as a username the name of another person or entity or
  that is not lawfully available for use, a name or trade mark that is
  subject to any rights of another person or entity other than you
  without appropriate authorization, or a name that is otherwise
  offensive, vulgar or obscene.
</p>

<h2>Intellectual Property</h2>

<p>
  The Service and its original content (excluding Content provided by
  users), features and functionality are and will remain the exclusive
  property of LockSign LLC and its licensors. The Service is protected
  by copyright, trademark, and other laws of both the United States and
  foreign countries. Our trademarks and trade dress may not be used in
  connection with any product or service without the prior written
  consent of LockSign LLC.
</p>

<h2>Links To Other Web Sites</h2>

<p>
  Our Service may contain links to third-party web sites or services
  that are not owned or controlled by LockSign LLC.
</p>

<p>
  LockSign LLC has no control over, and assumes no responsibility for,
  the content, privacy policies, or practices of any third party web
  sites or services. You further acknowledge and agree that LockSign LLC
  shall not be responsible or liable, directly or indirectly, for any
  damage or loss caused or alleged to be caused by or in connection with
  use of or reliance on any such content, goods or services available on
  or through any such web sites or services.
</p>

<p>
  We strongly advise you to read the terms and conditions and privacy
  policies of any third-party web sites or services that you visit.
</p>

<h2>Termination</h2>

<p>
  We may terminate or suspend your account immediately, without prior
  notice or liability, for any reason whatsoever, including without
  limitation if you breach the Terms.
</p>

<p>
  Upon termination, your right to use the Service will immediately
  cease. If you wish to terminate your account, you may simply
  discontinue using the Service.
</p>

<h2>Limitation Of Liability</h2>

<p>
  In no event shall LockSign LLC, nor its directors, employees,
  partners, agents, suppliers, or affiliates, be liable for any
  indirect, incidental, special, consequential or punitive damages,
  including without limitation, loss of profits, data, use, goodwill, or
  other intangible losses, resulting from (i) your access to or use of
  or inability to access or use the Service; (ii) any conduct or content
  of any third party on the Service; (iii) any content obtained from the
  Service; and (iv) unauthorized access, use or alteration of your
  transmissions or content, whether based on warranty, contract, tort
  (including negligence) or any other legal theory, whether or not we
  have been informed of the possibility of such damage, and even if a
  remedy set forth herein is found to have failed of its essential
  purpose.
</p>

<h2>Disclaimer</h2>

<p>
  Your use of the Service is at your sole risk. The Service is provided
  on an &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; basis. The
  Service is provided without warranties of any kind, whether express or
  implied, including, but not limited to, implied warranties of
  merchantability, fitness for a particular purpose, non-infringement or
  course of performance.
</p>

<p>
  LockSign LLC its subsidiaries, affiliates, and its licensors do not
  warrant that a) the Service will function uninterrupted, secure or
  available at any particular time or location; b) any errors or defects
  will be corrected; c) the Service is free of viruses or other harmful
  components; or d) the results of using the Service will meet your
  requirements.
</p>

<h2>Governing Law</h2>

<p>
  These Terms shall be governed and construed in accordance with the
  laws of Delaware, United States, without regard to its conflict of law
  provisions.
</p>

<p>
  Our failure to enforce any right or provision of these Terms will not
  be considered a waiver of those rights. If any provision of these
  Terms is held to be invalid or unenforceable by a court, the remaining
  provisions of these Terms will remain in effect. These Terms
  constitute the entire agreement between us regarding our Service, and
  supersede and replace any prior agreements we might have between us
  regarding the Service.
</p>

<h2>Changes</h2>

<p>
  We reserve the right, at our sole discretion, to modify or replace
  these Terms at any time. If a revision is material we will try to
  provide at least 30 days notice prior to any new terms taking effect.
  What constitutes a material change will be determined at our sole
  discretion.
</p>

<p>
  By continuing to access or use our Service after those revisions
  become effective, you agree to be bound by the revised terms. If you
  do not agree to the new terms, please stop using the Service.
</p>

<h2>Contact Us</h2>

<p>If you have any questions about these Terms, please contact us.</p>
        </div>
    );
}

export default Allsections;