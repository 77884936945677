import { useState } from 'react';
import style from '../../legislation.module.scss';

const FAQsections = () => {

  return (
    <div className={'column align-start pb-80 pt-24 px-8 min-h-screen'} style={{ textAlign: 'left' }}>
      <div className={`accordion-item ${ style.wrapper }`}>
        <h2 className={'accordion-header'} id="headingOne">
          <button className="accordion-button collapsed" style={{ background: '#1c1c1e', }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
            <h2 className="text-xl font-medium">
                What is LockSign
            </h2>
          </button>
        </h2>
        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
          <div className="accordion-body">
            <p className="text-base my-4 font-roboto">
            LockSign is an electronic signature platform that creates highly secure signatures. The signatures are not only unique to the user but also to the document and time signed
            </p>
          </div>
        </div>
      </div>

      <div className={`accordion-item ${ style.wrapper }`}>
        <h2 className={'accordion-header'} id="flush-headingTwo">
          <button className="accordion-button collapsed" style={{ background: '#1c1c1e', }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
            <h2 className="text-xl font-medium">What does LockSign do?</h2>
          </button>
        </h2>
        <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
          <div className="accordion-body">
            <div className="faq-answer">
              <p className="text-base my-4 font-roboto">
              LockSign combines various information into a QR code that is part of each electronic signature. The final signature is different for each signing and as such cannot be copied or forged.  An original signature is part of the LockSign signature so it can be used for all signing purposes.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={`accordion-item ${ style.wrapper }`}>
        <h2 className="accordion-header" id="flush-headingThree">
          <button className="accordion-button collapsed" style={{ background: '#1c1c1e', }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
            <div className="faq-question row center-start">
              <h2 className="text-xl font-medium">How is LockSign different?</h2>
            </div> 
          </button>
        </h2>
        <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
          <div className="accordion-body">
            <div className="faq-answer">
              <p className="text-base my-4 font-roboto">
              LockSign uses advanced algorithms and encryption to combine information and create each unique signature. All security features are within the LockSign signature and as such no additional tokens or certificates are required. This allows for simple signature verification via our platform. 
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={`accordion-item ${ style.wrapper }`}>
        <h2 className={'accordion-header'} id="headingOne">
          <button className="accordion-button collapsed" style={{ background: '#1c1c1e', }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
            <h2 className="text-xl font-medium">
                Is LockSign safe?
            </h2>
          </button>
        </h2>
        <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
          <div className="accordion-body">
            <p className="text-base my-4 font-roboto">
            Since each signature is unique the signatures cannot be forged or copied. Our signature verification system allows for recognition of any invalid signatures or document mismatches. All information used to create a LockSign signature is encrypted unique identification for each signature which is bound to a document and person.
            </p>
          </div>
        </div>
      </div>

      <div className={`accordion-item ${ style.wrapper }`}>
        <h2 className={'accordion-header'} id="headingOne">
          <button className="accordion-button collapsed" style={{ background: '#1c1c1e', }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
            <h2 className="text-xl font-medium">
                What document formats can I sign?
            </h2>
          </button>
        </h2>
        <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
          <div className="accordion-body">
            <p className="text-base my-4 font-roboto">
            All document types are supported. The documents are converted to PDF during the signing process. 
            </p>
          </div>
        </div>
      </div>

      <div className={`accordion-item ${ style.wrapper }`}>
        <h2 className={'accordion-header'} id="headingOne">
          <button className="accordion-button collapsed" style={{ background: '#1c1c1e', }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
            <h2 className="text-xl font-medium">
            How do I verify a signature?
            </h2>
          </button>
        </h2>
        <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
          <div className="accordion-body">
            <p className="text-base my-4 font-roboto">
            a)For PDF documents available electronically the platform automatically verifies or rejects the LockSign signature and its corresponding document
            </p>
            <p className="text-base my-4 font-roboto">
            b)For other documents (printed) the user can send a verification request to the owner via our platform. The original owner is recognized via the LockSign signature. The owner can either verify or reject the document. For this function a full screen view of the document is recommended.
            </p>
            <p className="text-base my-4 font-roboto">
            c)Users verifying their signature will be able to view the original document.
            </p>
          </div>
        </div>
      </div>

      <div className={`accordion-item ${ style.wrapper }`}>
        <h2 className={'accordion-header'} id="headingOne">
          <button className="accordion-button collapsed" style={{ background: '#1c1c1e', }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven">
            <h2 className="text-xl font-medium">
              Do recipients need to have a LockSign account?
            </h2>
          </button>
        </h2>
        <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
          <div className="accordion-body">
            <p className="text-base my-4 font-roboto">
            No. Documents can be sent to anyone.
            </p>
          </div>
        </div>
      </div>

      <div className={`accordion-item ${ style.wrapper }`}>
        <h2 className={'accordion-header'} id="headingOne">
          <button className="accordion-button collapsed" style={{ background: '#1c1c1e', }} type="button" data-bs-toggle="collapse" data-bs-target="#collapsEight" aria-expanded="true" aria-controls="collapsEight">
            <h2 className="text-xl font-medium">
              Are documents stored?
            </h2>
          </button>
        </h2>
        <div id="collapsEight" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
          <div className="accordion-body">
            <p className="text-base my-4 font-roboto">
            Documents are stored only for verification of LockSign signatures.
            </p>
          </div>
        </div>
      </div>


      <div className={`accordion-item ${ style.wrapper }`}>
        <h2 className={'accordion-header'} id="headingOne">
          <button className="accordion-button collapsed" style={{ background: '#1c1c1e', }} type="button" data-bs-toggle="collapse" data-bs-target="#collapsNine" aria-expanded="true" aria-controls="collapsNine">
            <h2 className="text-xl font-medium">
              Do we have access to your documents?
            </h2>
          </button>
        </h2>
        <div id="collapsNine" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
          <div className="accordion-body">
            <p className="text-base my-4 font-roboto">
            No. We do not have access to any documents submitted or sent via our platform.
            </p>
          </div>
        </div>
      </div>


      <div className={`accordion-item ${ style.wrapper }`}>
        <h2 className={'accordion-header'} id="headingOne">
          <button className="accordion-button collapsed" style={{ background: '#1c1c1e', }} type="button" data-bs-toggle="collapse" data-bs-target="#collapsTen" aria-expanded="true" aria-controls="collapsTen">
            <h2 className="text-xl font-medium">
              Do we store or have access to any biometric information?
            </h2>
          </button>
        </h2>
        <div id="collapsTen" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
          <div className="accordion-body">
            <p className="text-base my-4 font-roboto">
              No. The platform uses the biometric information of your existing smart device.
            </p>
          </div>
        </div>
      </div>


    </div>
  );
};

export default FAQsections;
