import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../apifetch';

const loginUser = createAsyncThunk(
    'user/login',
    async (data,thunkapi) => {
        const response = await api.post('/auth',{
            'email' : data.email,
            'password' : data.password
        });
        if(response) thunkapi.dispatch(setLoginState(true));
        return response.data;
    }
);

const verifyUser = createAsyncThunk(
    'user/verify',
    async (data,thunkapi) => {
        const response = await api.post('',data);
        if(response.data) thunkapi.dispatch(setVerifiedState(true));
        return response.data;
    }
);

const forgotPassword = createAsyncThunk(
    'user/forgotpassword',
    async (data,thunkapi) => {
        const response = await api.post('',data);
        return response.data
    }
);

const resetPassword = createAsyncThunk(
    'user/resetpassword',
    async (data,thunkapi) => {
        const response = await api.post('',data);
        return response.data;
    }
)

export { loginUser,verifyUser,forgotPassword,resetPassword };