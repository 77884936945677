import checkGray from '../../../../assets/check-gray.svg';
import checkWhite from '../../../../assets/check-white.svg';

import style from './Pricing.module.scss';

const Pricing = () => {
  return (
    <div className={ `container text-center ${ style.largeMargin }` }>
      <h2 className="fw-normal">Pricing</h2>
      <div className="row my-5">
        <div className="col-12 col-xl-10 offset-xl-1 col-xxl-8 offset-xxl-2">
          <div className="row d-flex justify-content-center">
            {/* <div className="col-12 col-md-4">
              <div className={ `me-md-3 ${ style.priceCard }` }>
                <h3>Free Trial</h3>
                <h6 className="text-muted">€<span className="text-white">0.00</span>/Month</h6>
                <hr className="bg-light opacity-75" />
                <ul className="list-unstyled text-start text-lightest-gray fs-11 flex-grow-1">
                  <li className="mb-2 d-flex align-children-center">
                    <img className="me-2" src={ checkWhite } alt="Check" />
                    <span className="text-white">Free trial of all features of the premium plan</span>
                  </li>
                  <li className="mb-2 d-flex align-children-center">
                    <img className="me-2" src={ checkWhite } alt="Check" />
                    <span className="text-white">Unlimited signature varification</span>
                  </li>
                  <li className=" d-flex align-items-children-center">
                    <img className="me-2" src={ checkWhite } alt="Check" />
                    <span className="text-white">Free for one month</span>
                  </li>
                </ul>
                <a href="#" className="btn btn-primary btn-lg px-4 fs-14">Try Free</a>
              </div>
            </div> */}
            <div className="col-12 col-md-4">
              <div className={ `ms-md-3 ${ style.priceCard }` }>
                <h3>Standard</h3>
                <h6 className="text-muted">€<span className="text-white">5.99</span>/Month</h6>
                <hr className="bg-light opacity-75" />
                <ul className="list-unstyled text-start text-lightest-gray fs-11 flex-grow-1">
                  <li className="mb-2 d-flex align-children-center">
                    <img className="me-2" src={ checkWhite } alt="Check" />
                    <span className="text-white">First Month Free</span>
                  </li>
                  <li className="mb-2 d-flex align-children-center">
                    <img className="me-2" src={ checkWhite } alt="Check" />
                    <span className="text-white">5 Secure Signatures a Month</span>
                  </li>
                  <li className=" d-flex align-items-childrenr">
                    <img className="me-2" src={ checkWhite } alt="Check" />
                    <span className="text-white">Unlimited Signature Verification</span>
                  </li>
                </ul>
                {/* <a href="#" className="btn btn-primary btn-lg px-4 fs-14">Select</a> */}
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className={ `ms-md-3 ${ style.priceCard }` }>
                <h3>Premium</h3>
                <h6 className="text-muted">€<span className="text-white">9.99</span>/Month</h6>
                <hr className="bg-light opacity-75" />
                <ul className="list-unstyled text-start text-lightest-gray fs-11 flex-grow-1">
                  <li className="mb-2 d-flex align-children-center">
                    <img className="me-2" src={ checkWhite } alt="Check" />
                    <span className="text-white">First Month Free</span>
                  </li>
                  <li className="mb-2 d-flex align-children-center">
                    <img className="me-2" src={ checkWhite } alt="Check" />
                    <span className="text-white">Unlimited Secure Signatures</span>
                  </li>
                  <li className=" d-flex align-items-childrenr">
                    <img className="me-2" src={ checkWhite } alt="Check" />
                    <span className="text-white">Unlimited Signature Verification</span>
                  </li>
                </ul>
                {/* <a href="#" className="btn btn-primary btn-lg px-4 fs-14">Select</a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
