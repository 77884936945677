import style from '../Recovery.module.scss';
import Layout from '../../../Layout';
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { api } from '../../../../states/apifetch';

import CheckMark from "./CheckMark.svg"
import XCircle from "./XCircle.svg"

const VerifyEmail = () => {

  const handleSubmit = event => {
    event.preventDefault();
  };
  const navigate = useNavigate();

  const { token } = useParams();
  console.log("Token : ",token);

  const [isLoading,setIsLoading] = useState(true);
  const [alert,setAlert] = useState(false); // True when email verified

  // Immediatly Invoked Function
  (async() => {
    api.post('/user/verify-email',{'code':token}).then(resp=>{
        setAlert(true);
        setIsLoading(false);
        console.log(resp);
    }).catch(err=>{
        setAlert(false);
        setIsLoading(false);
        console.error(err);
    })

  })();
  
  useEffect(()=>{
    if(!isLoading){
        setTimeout(()=>{
            navigate('/');
        }, 5000)
    }
  },[isLoading])

  return (
    <Layout>
    <div className="container text-center my-5">
      <div className="row">
        {!isLoading 
        && <div className={alert? "alert alert-success mt-5" : "alert alert-danger mt-5"} role="alert">
            {alert ? "Your email has been verified!" : "Verification cannot be completed!"}
            </div>
        }
            <div className={`col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 p-5 my-5 ${ style.form }` }>
            <h5 className="text-primary mb-5">Email Verification</h5>
            <div className="row my-4 justify-content-center">
                <div className="col-12 col-lg-5">
                
                {isLoading 
                ? <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
                </div> 
                : <img src={alert? CheckMark:XCircle} style={{height: 50, width: 50}} />
                }
                </div>
            </div>
            </div>
      </div>
    </div>
    </Layout>
  );
};

export default VerifyEmail;
