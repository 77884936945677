import logo from '../../../../assets/svg/white.svg';
import Layout from '../../../Layout';

import style from './NotFound.module.scss';

const NotFound = () => {
  return (
    <Layout>
    <div className={ `py-3 position-relative ${ style.wrapper } mt-5 mb-5` }>
      <div className="container text-center">
        <div className="mt-5 mb-4">
          <h1 className="fw-light text-uppercase">404</h1>
          <h1>Not Found</h1>
        </div>
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1 fw-light">
            Page you are looking for does not exist either deleted.
            You can go back to main page
          </div>
        </div>
        {/* THOSE BUTTONS WILL BE ADDED LATER */}
        <div className={ `mt-5 ${ style.buttons }` }>
          <a href="/" className="btn btn-primary btn-lg me-3 fs-14 mb-5">Go Back to Main Page</a>
          {/* <a href="#" className="btn btn-outline-primary btn-lg fs-14">Learn More</a> */}
        </div>
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1 mt-5">
            <img className={ style.phone1 } style={{height:100 , width: 100}} src={ logo } alt="Logo" />
          </div>
        </div>
      </div>
    </div>
    </Layout>
  );
};

export default NotFound;
