import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
// Import Slices
import { userSlice } from './slices/user/user';

export default configureStore({
    reducer : {
        // Define the Slices / other state stores here
        users: userSlice
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        thunk: {
            // Call asynchronous functions here
        }
    }),
    devTools: false
});
