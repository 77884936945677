import style from '../Recovery.module.scss';
import Layout from '../../../Layout';
import { useNavigate, useParams } from 'react-router';
import { useState } from 'react';
import { api } from '../../../../states/apifetch';
import ReCAPTCHA from 'react-google-recaptcha';
import sitekey from '../../constants';


const ResetPassword = () => {

  const [ tokenval, setTokenVal ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ retypepassword, setRetypePassword ] = useState('');

  const [ isLoading, setIsLoading ] = useState(false);
  const [ alert, setAlert ] = useState(null); // True when alert is displayed
  const [ alertType, setAlertType ] = useState(null); // True when alert is displayed
  const [ alertMessage, setAlertMessage ] = useState(null); // True when alert is displayed
  const [ captchaValid, setCaptchaValid ] = useState(false); // True when alert is displayed
  const navigator = useNavigate();

  console.log(`Recaptcha key : ${sitekey}`);

  const resetActions = (boolval, message) => {
    setIsLoading(false);
    setAlert(true);
    setAlertType(boolval);
    setAlertMessage(message);
    setTimeout(() => {
      navigator('/');
    }, 3000);
  };

  const setErrorActions = (boolval, message) => {
    setIsLoading(false);
    setAlert(true);
    setAlertType(boolval);
    setAlertMessage(message);
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  };

  const handleSubmit = event => {
    event.preventDefault();
    setIsLoading(true);
    if (password === retypepassword) {
      api.post('/user/reset-password', {
        password:password,
        code: token ?? tokenval
      }).then(resp => {
        resetActions(true, 'Your password has been changed successfuly!');
      }).catch(err => {
        resetActions(false, 'An error occured!/nPlease Try again later');
      });
    } else {
      setErrorActions(false, 'Password does not match!');
    }
    
  };

  function setCaptchaValidation(value) {
    setCaptchaValid(value);
  }

  const { token } = useParams();
  console.log('TOKEN : ', token);

  return (
    <Layout>
      <div className="container text-center my-5">
        <div className="row">
          {alert
        && <div className={alertType ? 'alert alert-success mt-5' : 'alert alert-danger mt-5'} role="alert">
          {alertMessage}
        </div>
          }
          <form
            onSubmit={ handleSubmit }
            className={ `col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 p-5 my-5 ${ style.form }` }>
            <h5 className="text-primary mb-5">Reset Password</h5>
            <div className="row my-4 justify-content-center">
              <div className="col-12 col-lg-5">
                <input
                  type="password"
                  className={ `form-control mb-3 ${ style.input }` }
                  placeholder="New Password"
                  value={password}
                  onChange={(e) => {setPassword(e.target.value);}} 
                />
                <input
                  type="password"
                  className={ `form-control my-3 ${ style.input }` }
                  placeholder="Re-type New Password" 
                  value={retypepassword}
                  onChange={(e) => {setRetypePassword(e.target.value);}}
                />
                {token ? '' :
                  <input
                    type="text"
                    className={ `form-control mt-3 ${ style.input }` }
                    placeholder="Reset token" 
                    value={tokenval}
                    onChange={(e) => {setTokenVal(e.target.value);}}
                  />
                }
                <ReCAPTCHA
                  sitekey={sitekey}
                  onChange={setCaptchaValidation}
                  theme={'dark'}
                  className="mt-5 mb-3"
                />
              </div>
            </div>
            <div className="text-lg-center ">
              {isLoading 
                ? <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div> 
                : <button
                  className={captchaValid ? `btn btn-outline-primary btn-lg fs-16 text-white ${ style.sendButton }` : `btn btn-outline-primary btn-lg fs-16 text-gray opacity-75 ${ style.sendButton }` }
                  type="submit"
                  disabled={!captchaValid}
                >
                  Send
                </button>
              }
            
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default ResetPassword;
