import { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Collapse } from 'bootstrap';

// import logo from '../../../assets/logo.png';
import whitelogo from '../../assets/svg/white.svg';
import navbarTogglerIcon from '../../assets/navbar-toggler-icon.svg';
import loginIcon from '../../assets/user.svg';

import style from './Header.module.scss';

const Header = ({ navigation }) => {
  const collapseRef = useRef();
  const [ collapse, setCollapse ] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const collapse = Collapse.getOrCreateInstance(collapseRef.current, {
      toggle: false,
    });
    setCollapse(collapse);
  }, []);

  useEffect(() => {
    collapse?.hide();
  }, [ location ]);

  return (
    <nav
      className="navbar fixed-top navbar-expand-lg navbar-dark bg-dark"
      id="main-navbar"
    >
      <div className="container">
        <Link to="/" className={`navbar-brand ${style.logoText}`}>
          <img
            src={whitelogo}
            alt="LockSign logo"
            className="me-3"
            style={{ width: '48px' }}
          />
          LockSign
        </Link>
        <button
          onClick={() => collapse?.toggle()}
          className="navbar-toggler border-0"
        >
          <img src={navbarTogglerIcon} alt="Toggle navigation" />
        </button>
        <div ref={collapseRef} className="collapse navbar-collapse">
          <ul className="navbar-nav ms-auto my-3 my-lg-0">
            {navigation.map(({ to, label }) => (
              <li key={label} className="nav-item mx-2">
                <a className="nav-link" href={to}>
                  {label}
                </a>
              </li>
            ))}
          </ul>
          <div className="ms-lg-4 mb-3 mb-lg-0">
            <a
              href="https://app.locksign.com"
              className="btn btn-link text-decoration-none me-3"
            >
              <img className="me-2" src={loginIcon} alt="Login" />
              Login
            </a>
            <a
              href="https://app.locksign.com/sign-up"
              className="btn btn-primary px-4 py-2"
            >
              <span className="small">Sign up</span>
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

Header.propTypes = {
  navigation: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

export default Header;
