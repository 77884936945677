import { createSlice } from '@reduxjs/toolkit';

import { forgotPassword, loginUser, resetPassword, verifyUser } from './userThunks';

// Slice

export const userSlice = createSlice({
    name: ' user',
    initialState: {
        id: null, 
        token: null, 
        refreshToken: null, 
        isLoggedIn: false,
        isUserVerified : null,
        userData : [], 
        verifyMessage : [], 
        forgotPasswordMessage : [], 
        resetPasswordMessage : [], 
    }, 
    reducers: {
        setLoginState: (state,action) => {
            state.isLoggedIn = action.payload; // Action have type and payload
        }, 
        setVerifiedState: (state,action) => {
            state.isUserVerified = action.payload; // Action have type and payload
        }, 
        setId : (state,action) => {
            state.id = action.payload;
        }, 
        setToken : (state,action) => {
            state.token = action.payload;
        }, 
        setRefreshToken : (state,action) => {
            state.refreshToken = action.payload; 
        }, 
        setUserData : (state,action) => {
            state.id = action.payload.id;
            state.token = action.payload.token;
            state.refreshToken = action.payload.refreshtoken;
        }, 
    } ,
    extraReducers: (builder) => {
        builder.addCase(loginUser.fulfilled, (state,action) => {
            state.userData.push(action.payload);
        });
        builder.addCase(verifyUser.fulfilled, (state,action) => {
            state.verifyMessage.push(action.payload);
        });
        builder.addCase(forgotPassword.fulfilled, (state,action) => {
            state.forgotPasswordMessage.push(action.payload);
        });
        builder.addCase(resetPassword.fulfilled, (state,action) => {
            state.resetPasswordMessage.push(action.payload);
        });
    } ,
}); 

export const { setLoginState,setId,setRefreshToken,setToken } = userSlice.actions; 

export default userSlice.reducer; 
