// import Layout from '../Layout';
// import Pricing from '../subcomps/Pricing/Pricing';
import ResetPassword from '../subcomps/ResetPassword/ResetPassword';
import ForgotPassword from '../subcomps/ForgotPassword/ForgotPassword';
import VerifyEmail from '../subcomps/VerifyEmail/VerifyEmail';
import NotFound from '../../main-layout/HomePage/NotFound/NotFound';
import { Route, Routes, } from 'react-router';
import { BrowserRouter, Outlet } from 'react-router-dom';

import style from './Main.module.scss';

const Main = () => {
  return (
    <Routes>
      <Route path="/" element={ <ForgotPassword/> } />
      <Route path="/forgot" element={ <ForgotPassword/> } />
      <Route path="/reset/:token" element={ <ResetPassword/> } />
      <Route path="/reset" element={ <ResetPassword/> } />
      <Route path="/verify/:token" element={ <VerifyEmail/> } />
      <Route path="/*" element={ <NotFound/> } />
    </Routes>
  );
};

export default Main;

