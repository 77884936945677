import style from '../Recovery.module.scss';
import Layout from '../../../Layout';
import { useState } from 'react';
import { api } from '../../../../states/apifetch';
import ReCAPTCHA from 'react-google-recaptcha';
import sitekey from '../../constants';

const ForgotPassword = () => {

  const [ email, setEmail ] = useState('');
  const [ isLoading, setIsLoading ] = useState(false);
  const [ alertMessage, setAlertMessage ] = useState(''); // Display only when alert is true
  const [ alert, setAlert ] = useState(null); // True when alert is displayed
  const [ captchaValid, setCaptchaValid ] = useState(false); // True when alert is displayed



  const resetAllState = () => {
    setTimeout(() => {
      setAlert(false);
      setAlertMessage(false);
      setIsLoading(false);
    }, 3000);
  };

  const displayAlertTimedOut = (message, isSuccessful) => {
    setIsLoading(true);
    setAlert(isSuccessful);
    setAlertMessage(message);
    setTimeout(() => {
      setAlert(null);
      setAlertMessage('');
      setIsLoading(false);
    }, 3000);
  };

  const handleSubmit = event => {
    event.preventDefault();
    setIsLoading(true);
    console.log('EMAIL : ', email);
    if (email.indexOf(' ') >= 0) {
      displayAlertTimedOut('Email Cannot Contain Empty Spaces', false);
    }
    api.post('/user/forgot-password', { 'email':email }).then(resp => {
      displayAlertTimedOut('Further Instructions has been sent to the mail!', true);
    }).catch(err => {
      displayAlertTimedOut(err.response.data?.errors[0].message ?? 'Cannot Send Email to the User', false);
    });
  };

  function setCaptchaValidation(value) {
    setCaptchaValid(value);
  }

  return (
    <Layout>
      <div className="container text-center my-5">
        <div className="row">
          {alertMessage 
        && <div className={alert ? 'alert alert-success mt-5' : 'alert alert-danger mt-5'} role="alert">
          {alertMessage}
        </div>
          }
          <form
            onSubmit={ handleSubmit }
            className={ `col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 p-5 my-5 ${style.form}` }>
            <h5 className="text-primary mb-5">Forgot Password</h5>
            <p className=" mb-5 text-secondary">Please enter your email address to recovery your account </p>
            <div className="row my-4 justify-content-center">
              <div className="col-12 col-lg-5">
                <input
                  type="email"
                  className={ `form-control mb-3 ${ style.input } align-items-center` }
                  placeholder="Email"
                  value={email}
                  onChange={(e) => {setEmail(e.target.value);}} 
                />
              </div>
            </div>
            <div className="row my-4 justify-content-center">
              <div className="col-12 col-lg-5">
                <ReCAPTCHA
                  sitekey={sitekey}
                  onChange={setCaptchaValidation}
                  theme={'dark'}
                />
              </div>
            </div>
            <div className="text-lg-center">
              {isLoading
                ? <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div> 
                : <button
                  className={captchaValid ? `btn btn-outline-primary btn-lg fs-16 text-white ${ style.sendButton }` : `btn btn-outline-primary btn-lg fs-16 text-gray opacity-75 ${ style.sendButton }` }
                  type="submit"
                  disabled={!captchaValid}
                >
            Send
                </button>
              }
            
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default ForgotPassword;
