import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import logo from '../../assets/svg/white.svg';
import loginIcon from '../../assets/user.svg';
import playStore from '../../assets/google-play.svg';
import appStore from '../../assets/apple-store.svg';
import appStore_Download from '../../assets/download_on_the_app_store.svg';
import twitter from '../../assets/twitter.svg';
import linkedin from '../../assets/linkedin.svg';
import instagram from '../../assets/instagram.svg';
import facebook from '../../assets/facebook.svg';

import style from './Footer.module.scss';
import { useState } from 'react';

const Footer = ({ navigation }) => {
  const [ isSubscribed, setIsSubscribed ] = useState(null);
  const [ subscriptionEmail, setSubscriptionEmail ] = useState('');

  const imitiateSubscription = (e) => {
    e.preventDefault();
    setIsSubscribed(true);
    setTimeout(() => {
      setSubscriptionEmail('');
      setIsSubscribed(false);
    }, 3000);
  };
  return (
    <footer className="bg-gray text-white py-5 pt-4">
      <div className="container">
        <div className="row mb-2 d-none d-md-block">
          <div className="col-md-5 offset-md-6 offset-xl-7">
            <label
              htmlFor="subscribe-email-input"
              className="fw-bold fs-11">
                Subscribe to our newsletter
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-3 col-xl-2 text-center text-md-start">
            <Link to="/" className={ `text-decoration-none text-reset ${ style.logoText }` }>
              <img src={ logo } style={{ maxHeight: 52, maxWidth: 54 }} alt="LockSign logo" className="me-3" />
              LockSign
            </Link>
          </div>
          <div className="col-12 col-md-2">
            <ul className="nav flex-column mb-4 mb-md-0">
              {
                navigation.map(link => (
                  <li key={ link.label } className="nav-item text-center text-md-start mt-4 mt-md-0">
                    <a href={ link.to } className="nav-link p-1 text-reset fs-11">
                      { link.label }
                    </a>
                  </li>
                ))
              }
            </ul>
          </div>
          <div className="col-12 col-md-2 col-xl-3 d-none d-xl-block">
            {/* <Link to="#login" className="btn btn-link fs-12 text-decoration-none px-1 px-lg-auto me-lg-3">
              <img className="me-2" src={ loginIcon } alt="Login" />
              Login
            </Link>
            <Link to="#register" className="btn btn-primary fs-12 px-1 px-lg-4 py-2">
              <span className="small">Get Started</span>
            </Link> */}
          </div>
          <div className="col-12 col-md-6 offset-md-1 col-xl-5 offset-xl-0">
            <form className="row" onSubmit={ imitiateSubscription }>
              <div className="col-10 offset-1 col-sm-7 offset-sm-0">
                <input
                  id="subscribe-email-input"
                  type="email"
                  className={ `form-control h-100 bg-light-gray ${ style.emailInput }` }
                  placeholder="name@example.com" 
                  value={subscriptionEmail}
                  onChange={(e) => {setSubscriptionEmail(e.target.value);}}
                />
              </div>
              <div className="col-10 offset-1 col-sm-5 offset-sm-0 mt-3 mt-sm-0">
                <button className="btn btn-primary fs-12 w-100 py-2" type="submit">
                  <span className="small">Send</span>
                </button>
              </div>
              {isSubscribed
                ?
                <div>Thank you for subscribing to our newsletter</div>
                : ''
              }
            </form>
          </div>
        </div>
        <div className="my-4 text-center text-md-start">
          <a href="https://play.google.com" rel="noreferrer" target="_blank">
            {/* <img src={ playStore } alt="Google Play" /> */}
          </a>
          <a className="ms-2" href="https://apps.apple.com/app/apple-store/id1571283461?pt=120459558&ct=web-bottom&mt=8" rel="noreferrer" target="_blank">
            <img src={ appStore_Download } alt="Apple App Store" />
          </a>
        </div>
        <div className="d-lg-flex justify-content-between align-items-center text-center text-lg-start">
          <div className="d-lg-flex align-items-center text-light flex-grow-1 fs-11">
            <div>Locksign 2022. All rights reserved.</div>
            <ul className={ `list-unstyled m-0 ms-lg-3 my-3 my-lg-0 d-flex justify-content-center ${ style.footerLinks }` }>
              {/* <li><a href="#">Sitemap</a></li> */}
              <li><a href="#">Legal Notice</a></li>
              <li><a href="/terms-and-conditions">Terms and Conditions</a></li>
              <li><a href="/privacy-policy">Privacy Policies</a></li>
            </ul>
            <div>
              <a className="text-decoration-none fw-bold ms-lg-4" href="mailto:support@locksign.com">
                support@locksign.com
              </a>
            </div>
          </div>
          <div className="mt-3 mt-lg-0">
            <a href="https://twitter.com/locksignapp" rel="noreferrer" target="_blank">
              <img className="ms-2" src={ twitter } alt="Twitter" />
            </a>
            <a href="https://www.linkedin.com/company/locksign" rel="noreferrer" target="_blank">
              <img className="ms-2" src={ linkedin } alt="LinkedIn" />
            </a>
            <a href="https://instagram.com/locksignapp" rel="noreferrer" target="_blank">
              <img className="ms-2" src={ instagram } alt="Instagram" />
            </a>
            <a href="https://www.facebook.com/locksignapp" rel="noreferrer" target="_blank">
              <img className="ms-2" src={ facebook } alt="Facebook" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  navigation: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

export default Footer;
