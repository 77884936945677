import style from '../legislation.module.scss';
import Layout from '../../Layout';

import Allsections from './sections/allsections';

const TermsConditions = () => {
    return (
        <Layout>
        <div className={style.page}>
            <div className={ `py-3 position-relative ${ style.wrapper }` }>
                <div className="container text-center pt-5">
                    <div className="mt-5 mb-4">
                        <h1 className="fw-light text-uppercase">Terms and Conditions</h1>
                        <div className="row">
                            <Allsections />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </Layout>
    )
};

export default TermsConditions;