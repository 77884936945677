import { Navigate, Route, Routes } from 'react-router';
import { BrowserRouter,Outlet } from 'react-router-dom';
import HomePage from './main-layout/HomePage/HomePage';
import Main from './account-recovery/Main/Main';
import TermsConditions from './legistation-pages/terms-conditions/TermsConditions';
import PrivacyPolicy from './legistation-pages/privacy-policies/PrivacyPolicy';
import FAQ from './legistation-pages/frequently-asked-questions/FAQ';
import NotFound from './main-layout/HomePage/NotFound/NotFound';

const App = () => {
  return (
    <Routes>
      
      <Route path="/" element={ <HomePage/> } />
      <Route path="/recovery/*" element={ <Main/> } />
      <Route path="/terms-and-conditions" element={ <TermsConditions/> } />
      <Route path="/privacy-policy" element={ <PrivacyPolicy/> } />
      <Route path="/frequently-asked-questions" element={ <FAQ/> } />
      <Route path="/faq" element={ <Navigate to="/frequently-asked-questions" /> } />
      <Route path="/*" element={ <NotFound/> } />
    </Routes>
  );
};

export default App;
