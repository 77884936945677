import axios from 'axios';

export const api = axios.create({
    baseURL: 'https://api.locksign.com',
    headers: {
        'Content-Type': 'application/json',
        'x-api-key-id':'UIMUTECO0OI3M1L',
        'x-api-key':'14y2aNdZZC1nlBPueqa6DlkxfBTA2lDiNQFfdJTVmMGuCJLuYw'
    },
});
