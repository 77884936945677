import PropTypes from 'prop-types';

import Header from './Header/Header';
import Footer from './Footer/Footer';

const navigation = [
  { to: '#about', label: 'About LockSign' },
  { to: '#why', label: 'Why LockSign' },
  { to: '#pricing', label: 'Pricing' },
  { to: '#contact', label: 'Contact' },
];

const Layout = ({ children }) => {
  return (
    <>
      <Header navigation={ navigation } />
      { children }
      <Footer navigation={ navigation } />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node
};

export default Layout;
