import About from './About/About';
import Why from './Why/Why';
import Pricing from './Pricing/Pricing';
import Contact from './Contact/Contact';
import Layout from '../../Layout';

import style from './HomePage.module.scss';

const HomePage = () => {
  return (
    <Layout>
      <div className="offset-page">
        <div id="about" className={ style.page }>
          <About />
        </div>
        <div id="why" className={ style.page }>
          <Why/>
        </div>
        <div id="pricing" className={ style.page }>
          <Pricing/>
        </div>
        <div id='support'>
        <div id="contact" className={ style.page }>
          <Contact/>
        </div>
        </div>
      </div>
    </Layout>
  );
};

export default HomePage;
