import signatures from '../../../../assets/signatures.png';
import appStore_Download from '../../../../assets/download_on_the_app_store.svg';

import style from './Why.module.scss';

const Why = () => {
  return (
    <div className={ style.wrapper }>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-md-6 text-center text-md-start">
            <h2 className="fw-normal">Why LockSign</h2>
            <h5 className="text-primary fw-normal">Sign any document, anywhere!</h5>
            <div className="my-5">
              Compared to conventional signatures Locksign is a signature that is unique to each particular signing.
              The data produced by each signing changes and so does the signature.
              As such the signature cannot be used on any other document.
              Our platform allows for easy verification by the user and any authorized individual.
            </div>
            {/* <a href="#" className="btn btn-primary btn-lg fs-14 px-5 py-3">Let's Get Started</a> */}
            <div className={ 'mt-5' }>
              {/* <a href="#" className="btn btn-primary btn-lg me-3 fs-14 disabled">Coming Soon</a> */}
              <a className="ms-2" href="https://apps.apple.com/app/apple-store/id1571283461?pt=120459558&ct=web-middle&mt=8" rel="noreferrer" target="_blank">
                <img src={ appStore_Download } alt="Apple App Store" className={`img-fluid center-block ${style.imagebutton}`}/>
              </a>
              {/* <a href="#" className="btn btn-outline-primary btn-lg fs-14">Learn More</a> */}
            </div>
          </div>
          <div className="col-8 offset-2 col-md-5 offset-md-1">
            <img className={ `w-100 ${ style.signatures }` } src={ signatures } alt="Signatures" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Why;
