const Allsections = () => {
    return (
        <div>
          <p>Effective date: September 09, 2019</p>

<p>
  LockSign LLC (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;)
  operates{" "}
  <a href="../fa45885c-78c7-4bbe-b94a-fe7a8748a1f2-4f865a63-ed95-4756-8cf5-48b7ab777aee/www.locksign.com">
    <span>www.locksign.com</span>
  </a>{" "}
  and the LockSign mobile application (hereinafter referred to as the
  &quot;Service&quot;).
</p>

<p>
  This page informs you of our policies regarding the collection, use
  and disclosure of personal data when you use our Service and the
  choices you have associated with that data.
</p>

<p>
  We use your data to provide and improve the Service. By using the
  Service, you agree to the collection and use of information in
  accordance with this policy. Unless otherwise defined in this Privacy
  Policy, the terms used in this Privacy Policy have the same meanings
  as in our Terms and Conditions.
</p>

<h2>Definitions</h2>

<p>
  <h3>Service</h3>
</p>

<p>
  Service means{" "}
  <a href="../fa45885c-78c7-4bbe-b94a-fe7a8748a1f2-4f865a63-ed95-4756-8cf5-48b7ab777aee/www.locksign.com">
    <span>www.locksign.com</span>
  </a>{" "}
  and the LockSign mobile application operated by LockSign LLC
</p>

<p>
  <h3>Personal Data</h3>
</p>

<p>
  Personal Data means data about a living individual who can be
  identified from those data (or from those and other information either
  in our possession or likely to come into our possession).
</p>

<p>
  <h3>Usage Data</h3>
</p>

<p>
  Usage Data is data collected automatically either generated by the use
  of the Service or from the Service infrastructure itself (for example,
  the duration of a page visit).
</p>

<p>
  <h3>Cookies</h3>
</p>

<p>
  Cookies are small files stored on your device (computer or mobile
  device).
</p>

<p>
  <h3>Data Controller</h3>
</p>

<p>
  Data Controller means the natural or legal person who (either alone or
  jointly or in common with other persons) determines the purposes for
  which and the manner in which any personal information are, or are to
  be, processed.
</p>

<p>
  For the purpose of this Privacy Policy, we are a Data Controller of
  your Personal Data.
</p>

<p>
  <h3>Data Processors (or Service Providers)</h3>
</p>

<p>
  Data Processor (or Service Provider) means any natural or legal person
  who processes the data on behalf of the Data Controller.
</p>

<p>
  We may use the services of various Service Providers in order to
  process your data more effectively.
</p>

<p>
  <h3>Data Subject (or User)</h3>
</p>

<p>
  Data Subject is any living individual who is using our Service and is
  the subject of Personal Data.
</p>

<h2>Information Collection and Use</h2>

<p>
  We collect several different types of information for various purposes
  to provide and improve our Service to you.
</p>

<h3>Types of Data Collected</h3>

<h4>Personal Data</h4>

<p>
  While using our Service, we may ask you to provide us with certain
  personally identifiable information that can be used to contact or
  identify you (&quot;Personal Data&quot;). Personally identifiable
  information may include, but is not limited to:
</p>
<div className="container">
        <div className="row align-items-center">
        <ul style={{listStyle:"none"}}className="centered">
          <li>
            Email Address
          </li>
          <li>
            First name and last name
          </li>
          <li>
          Phone number
          </li>
          <li>
          Address, State, Province, ZIP/Postal code, City
          </li>
          <li>
          Cookies and Usage Data
          </li>
        </ul>
        </div>
        </div>

<p>
  We may use your Personal Data to contact you with newsletters,
  marketing or promotional materials and other information that may be
  of interest to you. You may opt out of receiving any, or all, of these
  communications from us by following the unsubscribe link or the
  instructions provided in any email we send.
</p>

<h4>Usage Data</h4>

<p>
  We may also collect information that your browser sends whenever you
  visit our Service or when you access the Service by or through a
  mobile device (&quot;Usage Data&quot;). This Usage Data may include
  information such as your computer's Internet Protocol address (e.g. IP
  address), browser type, browser version, the pages of our Service that
  you visit, the time and date of your visit, the time spent on those
  pages, unique device identifiers and other diagnostic data. When you
  access the Service with a mobile device, this Usage Data may include
  information such as the type of mobile device you use, your mobile
  device unique ID, the IP address of your mobile device, your mobile
  operating system, the type of mobile Internet browser you use, unique
  device identifiers and other diagnostic data.
</p>

<h4>Tracking &amp; Cookies Data</h4>

<p>
  We use cookies and similar tracking technologies to track the activity
  on our Service and we hold certain information. Cookies are files with
  a small amount of data which may include an anonymous unique
  identifier. Cookies are sent to your browser from a website and stored
  on your device. Other tracking technologies are also used such as
  beacons, tags and scripts to collect and track information and to
  improve and analyse our Service. You can instruct your browser to
  refuse all cookies or to indicate when a cookie is being sent.
  However, if you do not accept cookies, you may not be able to use some
  portions of our Service. Examples of Cookies we use:
</p>

<p>
  
  <h3>Session Cookies.</h3> We use Session Cookies to operate our Service.
</p>

<p>
  
  <h3>Preference Cookies.</h3> We use Preference Cookies to remember your
  preferences and various settings.
</p>

<p>
  
  <h3>Security Cookies.</h3> We use Security Cookies for security
  purposes.
</p>

<h2>Use of Data</h2>

<p>LockSign LLC uses the collected data for various purposes:</p>

<p>
  
  To provide and maintain our Service
</p>

<p>
  
  To notify you about changes to our Service
</p>

<p>
  
  To allow you to participate in interactive features of our Service
  when you agreement_for to do so
</p>

<p>
  
  To provide customer support
</p>

<p>
  
  To gather analysis or valuable information so that we can improve our
  Service
</p>

<p>
  
  To monitor the usage of our Service
</p>

<p>
  
  To detect, prevent and address technical issues
</p>

<p>
  
  To provide you with news, special offers and general information about
  other goods, services and events which we offer that are similar to
  those that you have already purchased or enquired about unless you
  have opted not to receive such information
</p>

<h2>
  Legal Basis for Processing Personal Data under the General Data
  Protection Regulation (GDPR)
</h2>

<p>
  If you are from the European Economic Area (EEA), LockSign LLC legal
  basis for collecting and using the personal information described in
  this Privacy Policy depends on the Personal Data we collect and the
  specific context in which we collect it. LockSign LLC may process your
  Personal Data because:
</p>

<p>
  
  We need to perform a contract with you
</p>

<p>
  
  You have given us permission to do so
</p>

<p>
  
  The processing is in our legitimate interests and it is not overridden
  by your rights
</p>

<p>
  
  For payment processing purposes
</p>

<p>
  
  To comply with the law
</p>

<h2>Retention of Data</h2>

<p>
  LockSign LLC will retain your Personal Data only for as long as is
  necessary for the purposes set out in this Privacy Policy. We will
  retain and use your Personal Data to the extent necessary to comply
  with our legal obligations (for example, if we are required to retain
  your data to comply with applicable laws), resolve disputes and
  enforce our legal agreements and policies. LockSign LLC will also
  retain Usage Data for internal analysis purposes. Usage Data is
  generally retained for a shorter period of time, except when this data
  is used to strengthen the security or to improve the functionality of
  our Service, or we are legally obligated to retain this data for
  longer periods.
</p>

<h2>Transfer of Data</h2>

<p>
  Your information, including Personal Data, may be transferred to - and
  maintained on - computers located outside of your state, province,
  country or other governmental jurisdiction where the data protection
  laws may differ from those of your jurisdiction.
</p>

<p>
  If you are located outside and choose to provide information to us,
  please note that we transfer the data, including Personal Data, to and
  process it there.
</p>

<p>
  Your consent to this Privacy Policy followed by your submission of
  such information represents your agreement to that transfer.
</p>

<p>
  LockSign LLC will take all the steps reasonably necessary to ensure
  that your data is treated securely and in accordance with this Privacy
  Policy and no transfer of your Personal Data will take place to an
  organisation or a country unless there are adequate controls in place
  including the security of your data and other personal information.
</p>

<h2>Disclosure of Data</h2>

<h3>Business Transaction</h3>

<p>
  If LockSign LLC is involved in a merger, acquisition or asset sale,
  your Personal Data may be transferred. We will provide notice before
  your Personal Data is transferred and becomes subject to a different
  Privacy Policy.
</p>

<h3>Disclosure for Law Enforcement</h3>

<p>
  Under certain circumstances, LockSign LLC may be required to disclose
  your Personal Data if required to do so by law or in response to valid
  requests by public authorities (e.g. a court or a government agency).
</p>

<h3>Legal Requirements</h3>

<p>
  LockSign LLC may disclose your Personal Data in the good faith belief
  that such action is necessary to:
</p>

<p>
  
  To comply with a legal obligation
</p>

<p>
  
  To protect and defend the rights or property of LockSign LLC
</p>

<p>
  
  To prevent or investigate possible wrongdoing in connection with the
  Service
</p>

<p>
  
  To protect the personal safety of users of the Service or the public
</p>

<p>
  
  To protect against legal liability
</p>

<h2>Security of Data</h2>

<p>
  The security of your data is important to us but remember that no
  method of transmission over the Internet or method of electronic
  storage is 100% secure. While we strive to use commercially acceptable
  means to protect your Personal Data, we cannot guarantee its absolute
  security.
</p>

<h2>
  Our Policy on &quot;Do Not Track&quot; Signals under the California
  Online Protection Act (CalOPPA)
</h2>

<p>
  We do not support Do Not Track (&quot;DNT&quot;). Do Not Track is a
  preference you can set in your web browser to inform websites that you
  do not want to be tracked.
</p>

<p>
  You can enable or disable Do Not Track by visiting the Preferences or
  Settings page of your web browser.
</p>

<h2>
  Your Data Protection Rights under the General Data Protection
  Regulation (GDPR)
</h2>

<p>
  If you are a resident of the European Economic Area (EEA), you have
  certain data protection rights. LockSign LLC aims to take reasonable
  steps to allow you to correct, amend, delete or limit the use of your
  Personal Data.
</p>

<p>
  If you wish to be informed about what Personal Data we hold about you
  and if you want it to be removed from our systems, please contact us.
</p>

<p>
  In certain circumstances, you have the following data protection
  rights:
</p>

<p>
  <h3>
    The right to access, update or delete the information we have on
    you.
  </h3>{" "}
  Whenever made possible, you can access, update or request deletion of
  your Personal Data directly within your account settings section. If
  you are unable to perform these actions yourself, please contact us to
  assist you.
</p>

<p>
  <h3>The right of rectification.</h3> You have the right to have your
  information rectified if that information is inaccurate or incomplete.
</p>

<p>
  <h3>The right to object.</h3> You have the right to object to our
  processing of your Personal Data.
</p>

<p>
  <h3>The right of restriction.</h3> You have the right to request that we
  restrict the processing of your personal information.
</p>

<p>
  <h3>The right to data portability.</h3> You have the right to be
  provided with a copy of the information we have on you in a
  structured, machine-readable and commonly used format.
</p>

<p>
  <h3>The right to withdraw consent.</h3> You also have the right to
  withdraw your consent at any time where LockSign LLC relied on your
  consent to process your personal information.
</p>

<p>
  Please note that we may ask you to verify your identity before
  responding to such requests.
</p>

<p>
  You have the right to complain to a Data Protection Authority about
  our collection and use of your Personal Data. For more information,
  please contact your local data protection authority in the European
  Economic Area (EEA).
</p>

<h2>Service Providers</h2>

<p>
  We may employ third party companies and individuals to facilitate our
  Service (&quot;Service Providers&quot;), provide the Service on our
  behalf, perform Service-related services or assist us in analysing how
  our Service is used. These third parties have access to your Personal
  Data only to perform these tasks on our behalf and are obligated not
  to disclose or use it for any other purpose.
</p>

<h3>Analytics</h3>

<p>
  We may use third-party Service Providers to monitor and analyse the
  use of our Service.
</p>

<p>
  <h3>Google Analytics</h3>
</p>

<p>
  Google Analytics is a web analytics service offered by Google that
  tracks and reports website traffic. Google uses the data collected to
  track and monitor the use of our Service. This data is shared with
  other Google services. Google may use the collected data to
  contextualise and personalise the ads of its own advertising network.
</p>

<p>
  You can opt-out of having made your activity on the Service available
  to Google Analytics by installing the Google Analytics opt-out browser
  add-on. The add-on prevents the Google Analytics JavaScript (ga.js,
  analytics.js and dc.js) from sharing information with Google Analytics
  about visits activity.
</p>

<p>
  You may opt-out of certain Google Analytics features through your
  mobile device settings, such as your device advertising settings or by
  following the instructions provided by Google in their Privacy Policy:{" "}
  <a href="https://policies.google.com/privacy">
    <span>https://policies.google.com/privacy</span>
  </a>
</p>

<p>
  For more information on the privacy practices of Google, please visit
  the Google Privacy &amp; Terms web page:{" "}
  <a href="https://policies.google.com/privacy">
    <span>https://policies.google.com/privacy</span>
  </a>
</p>

<h3>Behavioral Remarketing</h3>

<p>
  LockSign LLC uses remarketing services to advertise on third party
  websites to you after you visited our Service. We and our third-party
  vendors use cookies to inform, optimise and serve ads based on your
  past visits to our Service.
</p>

<p>
  <h3>Google Ads (AdWords)</h3>
</p>

<p>
  Google Ads (AdWords) remarketing service is provided by Google Inc.
</p>

<p>
  You can opt-out of Google Analytics for Display Advertising and
  customise the Google Display Network ads by visiting the Google Ads
  Settings page:{" "}
  <a href="https://adssettings.google.com/">
    <span>https://adssettings.google.com/</span>
  </a>
</p>

<p>
  Google also recommends installing the Google Analytics Opt-out Browser
  Add-on -{" "}
  <a href="https://tools.google.com/dlpage/gaoptout">
    <span>https://tools.google.com/dlpage/gaoptout</span>
  </a>{" "}
  - for your web browser. Google Analytics Opt-out Browser Add-on
  provides visitors with the ability to prevent their data from being
  collected and used by Google Analytics.
</p>

<p>
  For more information on the privacy practices of Google, please visit
  the Google Privacy &amp; Terms web page:{" "}
  <a href="https://policies.google.com/privacy">
    <span>https://policies.google.com/privacy</span>
  </a>
</p>

<h3>Payments</h3>

<p>
  We may provide paid products and/or services within the Service. In
  that case, we use third-party services for payment processing (e.g.
  payment processors).
</p>

<p>
  We will not store or collect your payment card details. That
  information is provided directly to our third-party payment processors
  whose use of your personal information is governed by their Privacy
  Policy. These payment processors adhere to the standards set by
  PCI-DSS as managed by the PCI Security Standards Council, which is a
  joint effort of brands like Visa, MasterCard, American Express and
  Discover. PCI-DSS requirements help ensure the secure handling of
  payment information.
</p>

<p>The payment processors we work with are:</p>

<p>
  <h3>Apple Store In-App Payments</h3>
</p>

<p>
  Their Privacy Policy can be viewed at{" "}
  <a href="https://www.apple.com/legal/privacy/en-ww/">
    <span>https://www.apple.com/legal/privacy/en-ww/</span>
  </a>
</p>

<p>
  <h3>Google Play In-App Payments</h3>
</p>

<p>
  Their Privacy Policy can be viewed at{" "}
  <a href="https://policies.google.com/privacy">
    <span>https://policies.google.com/privacy</span>
  </a>
</p>

<p>
  <h3>Stripe</h3>
</p>

<p>
  Their Privacy Policy can be viewed at{" "}
  <a href="https://stripe.com/us/privacy">
    <span>https://stripe.com/us/privacy</span>
  </a>
</p>

<p>
  <h3>PayPal / Braintree</h3>
</p>

<p>
  Their Privacy Policy can be viewed at{" "}
  <a href="https://www.paypal.com/webapps/mpp/ua/privacy-full">
    <span>https://www.paypal.com/webapps/mpp/ua/privacy-full</span>
  </a>
</p>

<h2>Links to Other Sites</h2>

<p>
  Our Service may contain links to other sites that are not operated by
  us. If you click a third party link, you will be directed to that
  third party's site. We strongly advise you to review the Privacy
  Policy of every site you visit. We have no control over and assume no
  responsibility for the content, privacy policies or practices of any
  third party sites or services.
</p>

<h2>Children's Privacy</h2>

<p>
  Our Service does not address anyone under the age of 18
  (&quot;Children&quot;).
</p>

<p>
  We do not knowingly collect personally identifiable information from
  anyone under the age of 18. If you are a parent or guardian and you
  are aware that your Child has provided us with Personal Data, please
  contact us. If we become aware that we have collected Personal Data
  from children without verification of parental consent, we take steps
  to remove that information from our servers.
</p>

<h2>Changes to This Privacy Policy</h2>

<p>
  We may update our Privacy Policy from time to time. We will notify you
  of any changes by posting the new Privacy Policy on this page. We will
  let you know via email and/or a prominent notice on our Service, prior
  to the change becoming effective and update the &quot;effective
  date&quot; at the top of this Privacy Policy.
</p>

<p>
  You are advised to review this Privacy Policy periodically for any
  changes. Changes to this Privacy Policy are effective when they are
  posted on this page.
</p>

<h2>Contact Us</h2>

<p>
  If you have any questions about this Privacy Policy, please contact
  us:
</p>

<p>
  
  By email: <a className="text-decoration-none fw-italic ms-lg-4" href="mailto:support@locksign.com">
                support@locksign.com
              </a>
</p>
        </div>
    );
}

export default Allsections;