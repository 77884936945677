import style from '../legislation.module.scss';
import Layout from '../../Layout';

import FAQsections from './sections/FAQsections';

const TermsConditions = () => {
  return (
    <Layout>
      <div className={style.page}>
        <div className={ `py-3 position-relative ${ style.wrapper }` }>
          <div className="container text-center pt-5">
            <div className="mt-5 mb-4 pb-5 pt-5">
              <h1 className="fw-light text-uppercase">Frequently Asked Questions</h1>
              <div className="row pt-5 pb-5">
                <FAQsections />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TermsConditions;
